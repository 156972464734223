import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpInvoiceExplanationLink from '@components/DtpInvoiceExplanationLink/DtpInvoiceExplanationLink'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import DtpPaidInvoiceAccordionGroup from '@components/DtpPaidInvoiceAccordionGroup/DtpPaidInvoiceAccordionGroup'
import InvoicePageSkeleton from '@components/Skeletons/InvoicePageSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { Container, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

const Invoices = () => {
  const { t } = useTranslation()
  const { activeLocations, expiredLocations, isLoading: isLoadingLocations } = useLocationsData()
  const { invoices: openInvoicesData, isLoading: isLoadingOpenInvoices } = useInvoices('OPEN')
  const { invoices: paidInvoicesData, isLoading: isLoadingPaidInvoices } = useInvoices('PAID')

  const openInvoices = Object.values(openInvoicesData).flat()
  const paidInvoices = Object.entries(paidInvoicesData).reverse()

  if (isLoadingOpenInvoices || isLoadingPaidInvoices || isLoadingLocations) return <InvoicePageSkeleton />

  return (
    <Container>
      <Typography variant="heading2" sx={{ mt: { xs: 1.5, sm: 5, md: 10 }, mb: { xs: 1, sm: 3, md: 6 } }}>
        {t('contracts.invoices')}
      </Typography>

      <DtpInvoiceCardGroup invoices={openInvoices} />

      <Show when={!openInvoices.length}>
        <DtpNoOpenInvoices />
      </Show>

      <Stack width="fit-content" gap={0} ml={-1.5} sx={{ mb: { xs: 2, sm: 5 } }}>
        <DtpInvoiceExplanationLink />
      </Stack>

      <Show when={!!paidInvoices.length}>
        <DtpPaidInvoiceAccordionGroup
          activeLocations={activeLocations}
          expiredLocations={expiredLocations}
          invoices={paidInvoices}
        />
      </Show>
    </Container>
  )
}

export default Invoices
