import theme from '@assets/themes/theme'
import DtpInvoiceCard, { IDtpInvoiceCardProps } from '@components/DtpInvoiceCard/DtpInvoiceCard'
import { Box, useMediaQuery, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

import GroupCard from './GroupCard'

const DtpInvoiceCardGroup = ({ invoices }: { invoices: IDtpInvoiceCardProps[] }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box>
      <Typography variant="heading4" sx={{ mb: { xs: 3, md: 5 } }}>
        {t('invoices.invoice_no_open_invoices', { number: invoices.length })}
      </Typography>
      <Show when={invoices.length > 1 && isMobile}>
        <Box mb={2}>
          {invoices.map((invoice, index) => (
            <GroupCard {...invoice} key={index} />
          ))}
        </Box>
      </Show>
      <Show when={invoices.length > 1 && !isMobile}>
        <Stack mb={2} flexWrap="wrap" direction="row" columnGap={3}>
          {invoices.map((invoice, index) => (
            <DtpInvoiceCard {...invoice} key={index} />
          ))}
        </Stack>
      </Show>
      <Show when={invoices.length === 1}>
        <DtpInvoiceCard {...invoices[0]} />
      </Show>
    </Box>
  )
}

export default DtpInvoiceCardGroup
