import { SvgIcon, SvgIconProps } from '@mui/material'

import { ReactComponent as AlertTriangle } from './source/alertTriangle.svg'
import { ReactComponent as ArrowDiagonal } from './source/arrowDiagonal.svg'
import { ReactComponent as ArrowDown } from './source/arrowDown.svg'
import { ReactComponent as ArrowRight } from './source/arrowRight.svg'
import { ReactComponent as BillPost } from './source/billPost.svg'
import { ReactComponent as BillsMenuItem } from './source/billsMenuItem.svg'
import { ReactComponent as Calendar } from './source/calendar.svg'
import { ReactComponent as CheckCircle } from './source/checkCircle.svg'
import { ReactComponent as Clock } from './source/clock.svg'
import { ReactComponent as CloseButton } from './source/closeButton.svg'
import { ReactComponent as Compare } from './source/compareIcon.svg'
import { ReactComponent as ContractConsumption } from './source/contractConsumption.svg'
import { ReactComponent as Download } from './source/downloadIcon.svg'
import { ReactComponent as ElectricPole } from './source/electricPole.svg'
import { ReactComponent as ErrorExclamation } from './source/errorExclamation.svg'
import { ReactComponent as ExpiredContracts } from './source/expiredContractsIcon.svg'
import { ReactComponent as Home } from './source/home.svg'
import { ReactComponent as HourGlass } from './source/hourglass.svg'
import { ReactComponent as Information } from './source/information.svg'
import { ReactComponent as Installment } from './source/installment.svg'
import { ReactComponent as Invoice } from './source/invoice.svg'
import { ReactComponent as LogOut } from './source/logOut.svg'
import { ReactComponent as Logo } from './source/logoCKW.svg'
import { ReactComponent as PaidInvoices } from './source/paidInvoices.svg'
import { ReactComponent as PlantLeaf } from './source/plantLeaf.svg'
import { ReactComponent as PowerCable } from './source/powerCable.svg'
import { ReactComponent as PowerConsumption } from './source/powerConsuption.svg'
import { ReactComponent as Profile } from './source/profile.svg'
import { ReactComponent as Prosumer } from './source/prosumer.svg'
import { ReactComponent as Storybook } from './source/storybook.svg'

export const ArrowDownIcon = (props: SvgIconProps) => <SvgIcon component={ArrowDown} inheritViewBox {...props} />
export const CalendarIcon = (props: SvgIconProps) => <SvgIcon component={Calendar} inheritViewBox {...props} />
export const ErrorExclamationIcon = (props: SvgIconProps) => (
  <SvgIcon component={ErrorExclamation} inheritViewBox {...props} />
)
export const HomeIcon = (props: SvgIconProps) => <SvgIcon component={Home} inheritViewBox {...props} />
export const LogoIcon = (props: SvgIconProps) => <SvgIcon component={Logo} inheritViewBox {...props} />
export const LogOutIcon = (props: SvgIconProps) => <SvgIcon component={LogOut} inheritViewBox {...props} />
export const PowerConsumptionIcon = (props: SvgIconProps) => (
  <SvgIcon component={PowerConsumption} inheritViewBox {...props} />
)
export const ProfileIcon = (props: SvgIconProps) => <SvgIcon component={Profile} inheritViewBox {...props} />
export const StorybookIcon = (props: SvgIconProps) => <SvgIcon component={Storybook} inheritViewBox {...props} />
export const DownloadIcon = (props: SvgIconProps) => <SvgIcon component={Download} inheritViewBox {...props} />
export const CompareIcon = (props: SvgIconProps) => <SvgIcon component={Compare} inheritViewBox {...props} />
export const InvoiceIcon = (props: SvgIconProps) => <SvgIcon component={Invoice} inheritViewBox {...props} />
export const ContractConsumptionIcon = (props: SvgIconProps) => (
  <SvgIcon component={ContractConsumption} inheritViewBox {...props} />
)
export const ArrowRightIcon = (props: SvgIconProps) => <SvgIcon component={ArrowRight} inheritViewBox {...props} />
export const CloseButtonIcon = (props: SvgIconProps) => <SvgIcon component={CloseButton} inheritViewBox {...props} />
export const ExpiredContractsIcon = (props: SvgIconProps) => (
  <SvgIcon component={ExpiredContracts} inheritViewBox {...props} />
)

export const InfoIcon = (props: SvgIconProps) => <SvgIcon component={Information} inheritViewBox {...props} />
export const AlertTriangleIcon = (props: SvgIconProps) => (
  <SvgIcon component={AlertTriangle} inheritViewBox {...props} />
)
export const ClockIcon = (props: SvgIconProps) => <SvgIcon component={Clock} inheritViewBox {...props} />
export const CheckIcon = (props: SvgIconProps) => <SvgIcon component={CheckCircle} inheritViewBox {...props} />
export const ProsumerIcon = (props: SvgIconProps) => <SvgIcon component={Prosumer} inheritViewBox {...props} />
export const PowerCableIcon = (props: SvgIconProps) => <SvgIcon component={PowerCable} inheritViewBox {...props} />
export const ElectricPoleIcon = (props: SvgIconProps) => <SvgIcon component={ElectricPole} inheritViewBox {...props} />
export const PlantLeafIcon = (props: SvgIconProps) => <SvgIcon component={PlantLeaf} inheritViewBox {...props} />
export const ArrowDiagonalIcon = (props: SvgIconProps) => (
  <SvgIcon component={ArrowDiagonal} inheritViewBox {...props} />
)

export const BillPostIcon = (props: SvgIconProps) => <SvgIcon component={BillPost} inheritViewBox {...props} />
export const InstallmentIcon = (props: SvgIconProps) => <SvgIcon component={Installment} inheritViewBox {...props} />
export const HourGlassIcon = (props: SvgIconProps) => <SvgIcon component={HourGlass} inheritViewBox {...props} />
export const PaidInvoicesIcon = (props: SvgIconProps) => <SvgIcon component={PaidInvoices} inheritViewBox {...props} />
export const BillsMenuItemIcon = (props: SvgIconProps) => (
  <SvgIcon component={BillsMenuItem} inheritViewBox {...props} />
)
