import { prepackLocationData, formatContracts } from '@helpers/locationsFunctions'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { getContracts } from '@src/api/queries'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface IAddOn {
  gpnr: string
  supplyStartDate: string
  supplyEndDate: string
  meterId?: string
  locationId?: string
  contractId?: string
  hasMoreThanOneContract?: boolean
  isFeedInLocation?: boolean
  parentLocation?: string
  contractType?: string
  deliveryType?: string
  hasFeedInContractsInLocation?: boolean
  isFeedInContract?: boolean
}

const useLocationsData = () => {
  const [error, setError] = useState<AxiosError>()
  const { state } = useLocation()
  const { activeAccount } = useAccounts()
  const { gpnr } = activeAccount

  const selectedItem = useLocationStore((state) => state.selectedItem)
  const setSelectedItem = useLocationStore((state) => state.setSelectedItem)

  const showExpiredContracts = useLocationStore((state) => state.showExpiredContracts)
  const setShowExpiredContracts = useLocationStore((state) => state.setExpiredContracts)

  const handleChangeExpireContracts = () => {
    if (showExpiredContracts) {
      setSelectedItem(activeLocations[0])
      setShowExpiredContracts(!showExpiredContracts)
    }
    setShowExpiredContracts(!showExpiredContracts)
  }
  const { data, isLoading, isError } = useQuery(['locations', gpnr], () => getContracts(gpnr), {
    onError: setError,
    enabled: !!gpnr,
  })

  const activeLocations = useMemo(
    () => prepackLocationData(data?.body.locationsContractActive || [], gpnr),
    [data, gpnr]
  )

  const expiredLocations = useMemo(
    () => prepackLocationData(data?.body.locationsContractTerminated || [], gpnr),
    [data, gpnr]
  )

  const allLocations = useMemo(() => [...activeLocations, ...expiredLocations], [activeLocations, expiredLocations])

  const activeContracts = useMemo(() => formatContracts(data?.body.locationsContractActive || []), [data])
  const expiredContracts = useMemo(() => formatContracts(data?.body.locationsContractTerminated || []), [data])

  const isServerError =
    !!error && error?.response?.status && error.response.status >= 500 && error.response.status < 600

  useEffect(() => {
    if (allLocations.length) {
      if (!activeLocations.length) {
        setShowExpiredContracts(true)
        setSelectedItem(expiredLocations[1])
      } else {
        setSelectedItem(activeLocations[0])
      }
    }
  }, [allLocations, activeLocations, expiredLocations, setShowExpiredContracts, setSelectedItem])

  useEffect(() => {
    if (state && allLocations.length) {
      const { contractId, isContractTerminated } = state
      if (!showExpiredContracts && isContractTerminated) {
        setShowExpiredContracts(true)
      }

      const selectedContract = allLocations.find((item) => item.value === contractId)
      setSelectedItem(selectedContract)
    }
  }, [allLocations, state, setSelectedItem, showExpiredContracts, setShowExpiredContracts])

  return {
    activeLocations,
    expiredLocations,
    allLocations,
    activeContracts,
    expiredContracts,
    selectedItem,
    setSelectedItem,
    error,
    isError,
    isLoading,
    isServerError,
    showExpiredContracts,
    activeAccount,
    setShowExpiredContracts,
    handleChangeExpireContracts,
  }
}

export default useLocationsData
