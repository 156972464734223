import { DownloadIcon } from '@assets/icons'
import { cardPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { IInvoice } from '@interfaces/invoices'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import DtpPaidInvoiceItem from './DtpPaidInvoiceItem'

interface IPaidInvoiceAccordionProps {
  year: string
  expanded?: boolean | string
  handleChange?: (event: React.SyntheticEvent, newExpanded: boolean) => void
  data: IInvoice[]
  multipleContracts?: boolean
}

const DtpPaidInvoiceAccordion = ({
  year,
  expanded,
  handleChange,
  data,
  multipleContracts,
}: IPaidInvoiceAccordionProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <Accordion
      elevation={0}
      square
      expanded={expanded === year}
      disableGutters
      sx={{
        mb: '24px',
        backgroundColor: 'transparent',
        color: isMobile ? theme.palette.text.secondary : theme.palette.text.primary,
        borderBottom: `1px solid ${cardPalette.border}`,
        '&::before': {
          backgroundColor: 'transparent',
        },
      }}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          px: 0,
          pb: '16px',
          '&.Mui-expanded': {
            pb: 0,
          },
          transition: 'color .15s ease-out, background-color .15s ease-out',
          '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper, &:hover .MuiAccordionSummary-expandIconWrapper': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            transition: 'color .15s ease-out, background-color .15s ease-out',
          },
          '.MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        {year}
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, pt: 0 }}>
        <Stack direction="column" rowGap={theme.spacing(3)}>
          <DtpPaidInvoiceItem data={data} multipleContracts={multipleContracts ?? false} />
          <Box display="inline-block" textAlign="right" width="100%">
            <Button
              variant="outlined"
              size="small"
              fullWidth={isMobile ?? true}
              sx={{
                height: isMobile ? '48px' : '40px',
                p: '16px',
                color: theme.palette.primary.main,
                '& .MuiButton-startIcon > *:nth-of-type(1)': {
                  fontSize: '24px',
                },
              }}
              startIcon={<DownloadIcon />}
            >
              <Typography variant="bodyRegularEmphasized">{t('invoices.invoice_download_all_pdfs')}</Typography>
            </Button>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default DtpPaidInvoiceAccordion
