import { getAccounts } from '@api/queries'
import { IAccount, IAccountsResponse } from '@interfaces/accounts'
import { useAccountStore } from '@src/stores/accounts/useAccountStore'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

const useAccounts = () => {
  const [error, setError] = useState<AxiosError>()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const accounts = useAccountStore((state) => state.accounts)
  const setAccounts = useAccountStore((state) => state.setAccounts)

  const activeAccount = useAccountStore((state) => state.activeAccount)
  const setActiveAccount = useAccountStore((state) => state.setActiveAccount)
  const resetLocations = useLocationStore((state) => state.resetLocations)

  const { isLoading, refetch } = useQuery({
    queryKey: ['accounts'],
    queryFn: () => getAccounts(),
    enabled: false,
    onError: setError,
    onSuccess: ({ body }: IAccountsResponse) => {
      if (!body) return

      const setMappedAccounts = (accounts: IAccount[]) => {
        setAccounts(accounts)
        const activeAccount = accounts.find((account) => account.active)
        activeAccount && setActiveAccount(activeAccount)
      }

      const hasActiveAccount = body.accounts.some((account) => account.mainAccount)

      if (hasActiveAccount) {
        const mappedAccounts = body.accounts.map((account) => {
          return {
            ...account,
            active: activeAccount.gpnr ? activeAccount.gpnr === account.gpnr : !!account.mainAccount,
          }
        })
        setMappedAccounts(mappedAccounts)
      } else {
        body.accounts[0].active = true
        setMappedAccounts(body.accounts)
      }
    },
  })

  const isServerError =
    !!error && error?.response?.status && error.response.status >= 500 && error.response.status < 600

  const refetchAccounts = useCallback(async () => {
    if (!queryClient.getQueryData(['accounts'])) {
      await refetch()
    }
  }, [queryClient, refetch])

  const switchAccount = (item: IAccount) => {
    const updatedItems = accounts.map((account) => ({
      ...account,
      active: account.gpnr === activeAccount.gpnr,
    }))

    setAccounts(updatedItems)
    setActiveAccount(item)
    resetLocations()
    navigate('/')
  }

  useEffect(() => {
    refetchAccounts()
  }, [refetchAccounts])

  return { accounts, activeAccount, switchAccount, isLoading, refetch, isServerError }
}

export default useAccounts
