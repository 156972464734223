import { HomeIcon, PowerConsumptionIcon, BillsMenuItemIcon } from '@assets/icons'
import Contracts from '@pages/Contracts/Contracts'
import CriticalError from '@pages/CriticalError/CriticalError'
import Help from '@pages/Help/Help'
import Home from '@pages/Home/Home'
import InvoiceDetails from '@pages/InvoiceDetails/InvoiceDetails'
import Invoices from '@pages/Invoices/Invoices'
import MaintenancePage from '@pages/Maintenance/MaintenancePage'
import ManagedAccounts from '@pages/ManagedAccounts/ManagedAccounts'
import NotFound from '@pages/NotFound/NotFound'
import PowerConsumption from '@pages/PowerConsumption/PowerConsumption'
import Stormprodukte from '@pages/Stormprodukte/Stormprodukte'
import i18n from 'i18next'
import { Outlet } from 'react-router'

export const navRoutes = [
  {
    path: '/',
    element: <Home />,
    icon: <HomeIcon />,
    name: 'navigation.navi_home',
  },
  {
    path: i18n.t('navigation_url.energy_consumption'),
    element: <PowerConsumption />,
    name: 'navigation.navi_energy_consumption',
    icon: <PowerConsumptionIcon />,
  },
  {
    path: i18n.t('navigation_url.invoices'),
    element: <Outlet />,
    name: 'navigation.navi_invoices',
    icon: <BillsMenuItemIcon />,
    children: [
      { index: true, element: <Invoices /> },
      {
        path: ':id',
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: '/storm-produkte',
    element: <Stormprodukte />,
    // name: 'Stormprodukte',
  },
]

export const profileRoutes = [
  {
    path: i18n.t('navigation_url.contracts'),
    element: <Contracts />,
    name: 'navigation.navi_contracts',
  },
  {
    path: i18n.t('navigation_url.manage_accounts'),
    element: <ManagedAccounts />,
    name: 'navigation.navi_managed_accounts',
  },
]

export const routes = [
  ...navRoutes,
  ...profileRoutes,
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export const publicRoutes = [
  {
    path: '/',
    element: <CriticalError />,
  },
  {
    path: '*',
    element: <CriticalError />,
  },
]
export const maintenanceRoutes = [
  {
    path: '*',
    element: <MaintenancePage />,
  },
]
