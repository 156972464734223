import { ArrowForward } from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const MaintenancePage = () => {
  const { t } = useTranslation()
  return (
    <Stack height="100vh" direction="row" justifyContent="center" textAlign="center">
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
          px: {
            xs: 4,
            md: 1,
          },
        }}
        alignSelf="center"
        gap={2}
        display="flex"
        flexDirection="column"
        alignItems={'center'}
      >
        <CalendarTodayIcon fontSize="large" />
        <Typography variant="heading3">{t('coming_soon')}</Typography>
        <Typography variant="bodyRegular">{t('description_rebuild_portal')}</Typography>
        <Button
          LinkComponent={'a'}
          href="https://my.ckw.ch"
          target="_self"
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
        >
          {t('myckw_login')}
        </Button>
      </Box>
    </Stack>
  )
}

export default MaintenancePage
