import { withErrorLogging } from '@helpers/hof'

import { apiUser } from './index'

export const setMainAccount = withErrorLogging(async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiUser.post('/v1/user', {
    configuration: {
      mainAccount: gpnr,
    },
  })
  return data
})
