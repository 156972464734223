import { InfoIcon } from '@assets/icons'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Typography } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useState, isValidElement } from 'react'

interface ICustomContentProps {
  title?: string | React.ReactNode
  content: string | React.ReactNode
  onClose: () => void
}

interface IDtpInfoTooltipProps {
  title?: string | React.ReactNode
  content: string | React.ReactNode
  id: string | number
  tooltipWidth?: string | number
  iconSize?: string | number
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const tooltipStyles = {
  textAlign: 'left',
  padding: 3,
  borderRadius: 2,
  background: '#fff',
  border: '1px solid #EFF0EF',
  boxShadow: '0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A',
  '& .MuiTooltip-arrow': {
    fontSize: '25px',
    color: '#fff',
    '&:before': {
      border: '1px solid #EFF0EF',
    },
  },
}

const CustomContent = ({ title, content, onClose }: ICustomContentProps) => {
  const handleContentClick = (event: React.MouseEvent) => event.stopPropagation()

  const renderTitle = () => {
    if (typeof title === 'string') {
      return <Typography sx={{ my: 0 }}>{title}</Typography>
    }
    if (isValidElement(title)) {
      return title
    }
    return null
  }

  return (
    <Stack direction={renderTitle() === null ? 'row-reverse' : 'column'} onClick={handleContentClick}>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={1}>
        <Box alignSelf="center">{renderTitle()}</Box>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box>{content}</Box>
    </Stack>
  )
}

const DtpInfoTooltip = ({ content, title, id, placement, tooltipWidth, iconSize = 15 }: IDtpInfoTooltipProps) => {
  const [open, setOpen] = useState<string | number | null>(null)

  const handleToggleTooltip = (id: string | number) => setOpen(open === id ? null : id)

  const handleClickAway = () => setOpen(null)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip
        title={<CustomContent content={content} title={title} onClose={() => handleToggleTooltip(id)} />}
        PopperProps={{
          disablePortal: false,
        }}
        componentsProps={{
          tooltip: {
            sx: { ...tooltipStyles, maxWidth: tooltipWidth ?? 260 },
          },
        }}
        open={open === id}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        placement={placement ?? 'bottom'}
      >
        <IconButton onClick={() => handleToggleTooltip(id)} size="small" sx={{ padding: 0.25 }}>
          <InfoIcon sx={{ width: iconSize, height: iconSize }} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default DtpInfoTooltip
