import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import React from 'react'

interface IFeatureProps {
  flag: string
  children: React.ReactNode
}

const FeatureToggle = ({ flag, children }: IFeatureProps) => {
  const { features } = useFeatureToggle()
  const isEnabled = features[flag]
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
  if (isProduction) {
    return !isEnabled ? <>{children}</> : null
  }
  return <>{children}</>
}

export default FeatureToggle
