import { invoiceStatus } from '@enums/invoiceStatus'
import { Box, Typography, Stack } from '@mui/material'

import Dot from './Dot'
import { statusType } from './DtpInvoiceCardUtills'

const CardDotItem = ({
  title,
  value,
  invoiceStatus,
  bold,
}: {
  title: string
  value: string
  invoiceStatus: invoiceStatus
  bold?: boolean
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5 }}>
        {title}
      </Typography>
      <Stack flexDirection="row" alignItems="center">
        <Box
          sx={{
            marginBottom: 0.25,
            marginRight: 0.5,
            borderRadius: '100%',
            width: 8,
            height: 8,
            bgcolor: statusType[invoiceStatus].bgColor,
          }}
        />
        <Dot color={statusType[invoiceStatus].bgColor} />
        <Typography
          variant="bodySmall"
          color={statusType[invoiceStatus].textColor}
          fontFamily={bold ? 'GothamMedium' : 'GothamBook'}
          sx={{ my: 0.5 }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default CardDotItem
