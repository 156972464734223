import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const InvoiceDetails = () => {
  const { state } = useLocation()
  const { t } = useTranslation()

  return (
    <Container>
      <Typography variant="heading2">
        {t('invoices.invoice')} {state.invoiceId}
      </Typography>
    </Container>
  )
}

export default InvoiceDetails
