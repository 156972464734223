import { getAggregationMeasurements } from '@api/queries'
import { noDataBarValue, mappedMeasurementsData } from '@components/ChartControl/ChartControlUtils'
import { IAggregationMeasurementsProps } from '@interfaces/measurements'
import { useQuery } from '@tanstack/react-query'

const useMeasurements = (props: IAggregationMeasurementsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ['aggregationYear', { ...props }],
    queryFn: () => getAggregationMeasurements(props),
    enabled: !!props.gpnr && ((!!props.meterId && !!props.contractId) || !!props.locationId),
    onSuccess: ({ body: aggregationData }) => {
      mappedMeasurementsData(aggregationData)
      noDataBarValue(aggregationData)
      return aggregationData
    },
    cacheTime: 0,
  })

  return { data, isLoading }
}

export default useMeasurements
