import { StorybookIcon } from '@assets/icons'
import AccountsAccordion from '@components/AccountsAccordion/AccountsAccordion'
import useAccounts from '@hooks/useAccounts/useAccounts'
import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  Divider,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  useTheme,
  styled,
} from '@mui/material'
import FeatureToggle from '@src/ui/wrappers/FeatureToggle/FeatureToggle'
import Show from '@src/ui/wrappers/Show/Show'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { navRoutes, profileRoutes } from '../../../router/routes.config'
import { LogoutButton } from '../LogoutButton/LogoutButton'
import { NavItem } from './DesktopMenu'

const MobileNavItem = styled(NavLink)(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  padding: theme.spacing(1, 4),
  color: '#303636',
  textDecoration: 'none',
  fontFamily: 'GothamMedium',
  fontWeight: 500,
  fontSize: 'inherit',
  justifyContent: 'start',
  textAlign: 'left',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 15,
    top: 'calc(50% - 2.5px)',
    background: 'transparent',
    width: 5,
    height: 5,
    borderRadius: '100%',
    transition: 'all 0.3s ease',
  },
  '&.active': {
    color: theme.palette.primary.main,
    '&::before': {
      background: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  '&:hover': {
    '&::before': {
      background: theme.palette.text.primary,
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
  },
}))

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const toggleDrawer = () => setIsOpen((prevState) => !prevState)
  const { accounts, activeAccount, switchAccount } = useAccounts()
  const theme = useTheme()

  const NavItems = () => (
    <List>
      {navRoutes.map(({ path, name, icon }) => {
        if (!name) return
        return (
          <ListItem key={name} sx={{ p: 0, marginTop: 1 }}>
            <NavItem to={path} onClick={toggleDrawer}>
              <ListItemIcon sx={{ width: 10, minWidth: 36 }}>{icon}</ListItemIcon>
              <ListItemText sx={{ margin: 0 }}>{t(name)}</ListItemText>
            </NavItem>
          </ListItem>
        )
      })}
      <FeatureToggle flag="storybook">
        <NavItem to={'/storybook'} target="_blank" onClick={toggleDrawer}>
          <ListItemIcon sx={{ width: 10, minWidth: 36 }}>
            <StorybookIcon />
          </ListItemIcon>
          <ListItemText>Storybook</ListItemText>
        </NavItem>
      </FeatureToggle>
    </List>
  )

  const ProfileItems = () => {
    return (
      <>
        <Typography
          variant="bodySmall"
          fontFamily={'GothamBook'}
          fontWeight={400}
          sx={{ mx: { xs: 4, md: 1.5 }, my: 0, mt: { xs: 0.75, md: 0 } }}
          color={theme.palette.text.secondary}
        >
          {t('navigation.navi_my_profile')}
        </Typography>
        <List>
          {profileRoutes.map(({ path, name }) => {
            if (!name) return
            return (
              <ListItem key={name} sx={{ m: 0, my: 1, p: 0 }}>
                <MobileNavItem to={path} onClick={toggleDrawer} sx={{ py: 0 }}>
                  <ListItemText sx={{ m: 0 }}>{t(name)}</ListItemText>
                </MobileNavItem>
              </ListItem>
            )
          })}
        </List>
      </>
    )
  }

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flex: 1, justifyContent: 'flex-end' }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Drawer id="mobileNavDrawer" anchor={'top'} open={isOpen} onClose={toggleDrawer}>
        <NavItems />
        <Divider sx={{ mb: 2 }} />
        <ProfileItems />
        <Show when={accounts && accounts.length > 1}>
          <AccountsAccordion
            currentAccount={activeAccount}
            accounts={accounts}
            switchAccount={switchAccount}
            onClick={toggleDrawer}
          />
        </Show>
        <Divider sx={{ mb: 2 }} />
        <LogoutButton
          fullWidth
          sx={{
            marginLeft: 0,
            justifyContent: 'flex-start',
            padding: { xs: theme.spacing(0, 4.625) },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
      </Drawer>
    </Box>
  )
}

export default MobileMenu
