import { useAuth } from '@hooks/useAuth/useAuth'
import { Suspense, useMemo } from 'react'
import { useRoutes } from 'react-router-dom'

import PrivateRoute from '../helpers/PrivateRoute'
import { SuspenseLoading } from '../ui/components/SuspenseLoading/SuspenseLoading'
import { routes as routesArray, publicRoutes, maintenanceRoutes } from './routes.config'

export const Routes = () => {
  const { hasRole } = useAuth()
  const isBetaTester = hasRole('DTP.Beta_Tester')
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
  const routes = useRoutes(isBetaTester ? routesArray : isProduction ? maintenanceRoutes : routesArray)
  const renderRoutes = useMemo(() => routes, [routes])

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <PrivateRoute>
        <>{renderRoutes}</>
      </PrivateRoute>
    </Suspense>
  )
}

export const PublicRoutes = () => {
  const routes = useRoutes(publicRoutes)
  const renderRoutes = useMemo(() => routes, [routes])

  return <Suspense fallback={<SuspenseLoading />}>{renderRoutes}</Suspense>
}
